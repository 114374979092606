<template>
	<div class="p-grid">
		<div class="p-col-12">
            <h3 style="color:#ffcc2a;">{{$t('Cambio de Vehículo')}} </h3>
		</div>
		<div class="p-col-12" v-if="!displayeditar">
			<div class="card">
				<DataTable :value="poliza" :scrollable="true" scrollHeight="400px" :paginator="true" class="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-responsive p-datatable-customers"
                        :rows="25" dataKey="id" :rowHover="true" v-model:selection="selectedCustomers" :filters="filters">
                    <template #header>
                        <div class="table-header">
                            <span class="p-input-icon-right">
                                <i class="pi pi-search"/>
                                <InputText v-model="buscar" type="number"/>
                            </span>
                            <Button :label="$t('Buscar')" icon="pi pi-search" class="p-button-secondary" @click="Buscar"/>
                        </div>
                    </template>
                    <Column :header="$t('Estatus')" field="estado_name" :sortable="true" headerStyle="width: 150px">
                        <template #body="slotProps">
                            <div v-if="slotProps.data.estado==0">
                                <Avatar icon="bi bi-shield-slash" class="p-mr-2" size="large" style="background-color:red; color: #ffffff"/>
                                {{$t('Anulada')}}
                            </div>
                            <div v-else-if="slotProps.data.estado==1">
                                <Avatar icon="bi bi-shield-slash" class="p-mr-2" size="large" style="background-color:red; color: #ffffff"/>
                                {{$t('Inicial')}}
                            </div>
                            <div v-else-if="slotProps.data.estado==2">
                                <Avatar icon="bi bi-shield-slash" class="p-mr-2" size="large" style="background-color:red; color: #ffffff"/>
                                {{$t('Evaluación')}}
                            </div>
                            <div v-else-if="slotProps.data.estado==3">
                                <Avatar icon="bi bi-shield-slash" class="p-mr-2" size="large" style="background-color:red; color: #ffffff"/>
                                {{$t('Compromiso')}}
                            </div>
                            <div v-else-if="slotProps.data.estado==4">
                                <Avatar icon="bi bi-shield-exclamation" class="p-mr-2" size="large" style="background-color:yellow; color: #ffffff"/>
                                {{$t('Verificación  de Pago')}}
                            </div>
                            <div v-else-if="slotProps.data.estado==6">
                                <Avatar icon="bi bi-shield-slash" class="p-mr-2" size="large" style="background-color:red; color: #ffffff"/>
                                {{$t('Anulada')}}
                            </div>
                            <div v-else-if="slotProps.data.estado==7">
                                <Avatar icon="bi bi-shield-slash" class="p-mr-2" size="large" style="background-color:red; color: #ffffff"/>
                                {{$t('Proceso Renovación')}}
                            </div>
                            <div v-else-if="slotProps.data.vend<0">
                                <Avatar icon="bi bi-shield-slash" class="p-mr-2" size="large" style="background-color:red; color: #ffffff"/>
                                {{$t('Vencida')}}
                            </div>
                            <div v-else-if="slotProps.data.pendiente>0">
                                <Avatar icon="bi bi-shield-exclamation" class="p-mr-2" size="large" style="background-color:yellow; color: #ffffff"/>
                                {{$t('Pago Pendiente')}}
                            </div>
                            <div v-else-if="slotProps.data.proceso>0">
                                <Avatar icon="bi bi-shield-slash" class="p-mr-2" size="large" style="background-color:red; color: #ffffff"/>
                                {{$t('Suspendida')}} <br> ({{$t('Pago Vencido')}})
                            </div>
                            <div v-else>
                                <Avatar icon="bi bi-shield-check" class="p-mr-2" size="large" style="background-color:green; color: #ffffff"/>
                                {{$t('Activa')}}
                            </div>
                        </template>
                    </Column>        
                    <Column field="codigo" :header="$t('Póliza')+' #'" :sortable="true" headerStyle="width: 200px">
                        <template #body="slotProps">
                            {{slotProps.data.datos.codigo}}<br>
                            {{$t('Activación')}}: {{slotProps.data.datos.activacion}}<br>
                            {{$t('Vencimiento')}}: {{slotProps.data.datos.vence}}
                        </template>
                    </Column>
                    <Column field="cliente" :header="$t('Cliente')+' #'" :sortable="true" headerStyle="width: 120px">
                        <template #body="slotProps">
                            {{slotProps.data.datos.cliente}}                              
                        </template>
                    </Column>
                    <Column field="nombre" :header="$t('Nombre Completo')+' #'" :sortable="true" headerStyle="width: 150px">
                        <template #body="slotProps">
                            {{slotProps.data.datos.nombre}}                              
                        </template>
                    </Column>
                    <Column field="duracion_name" :header="$t('Duración')" :sortable="true" headerStyle="width: 110px">
                        <template #body="slotProps">
                            <div v-if="i18n.locale() == 'es'">{{slotProps.data.duracion_nombre}}</div>
                            <div v-else>{{slotProps.data.duracion_name}}</div>     
                        </template>
                    </Column>
					
                    <Column :header="$t('Acciones')" headerStyle="width: 120px">
                        <template #body="slotProps">
                            <Button icon="pi pi-check-circle" class="p-button-rounded p-button-secondary p-mr-2" @click="Editar(slotProps.data)" v-if="slotProps.data.estado==5"/>
                            <Button icon="pi pi-file-pdf" class="p-button-rounded p-button-primary p-mr-2" @click="VerPDF(slotProps.data)" v-if="slotProps.data.datos.cambio"/>
                            <div v-if="slotProps.data.migracion">{{$t('Migración')}}</div>
                            <div v-if="slotProps.data.datos.cambio">{{$t('Cambio de Vehículo')}}</div>
                        </template>
                    </Column>
                </DataTable>
			</div>
        </div>
		<div class="p-col-12" v-else>
            <div class="card">
                <h4>{{$t('Información Básica')}}</h4>
                <div class="p-fluid p-grid">
					<div class="p-col-3">
						{{$t('Póliza')+' #'}}: <strong>{{police.datos.codigo}}</strong>			
					</div>
					<div class="p-col-3">
						{{$t('Activación')}}: <strong>{{police.activacion}}</strong>			
					</div>
					<div class="p-col-3">
						{{$t('Vencimiento')}}: <strong>{{police.vence}}</strong>			
					</div>
					<div class="p-col-3">
						{{$t('Cliente')+' #'}}: <strong>{{police.datos.cliente}}</strong>			
					</div>
					<div class="p-col-3">
						{{$t('Fecha')}}: <strong>{{police.fecha}}</strong>			
					</div>
					<div class="p-col-3">
						{{$t('Número ID')}}: <strong>{{police.code}}</strong>			
					</div>
					<div class="p-col-3" v-if="i18n.locale() == 'es' && police.tipocliente==1">
						{{$t('Sexo')}}: <strong>{{police.sexnombre}}</strong> 			
					</div>
					<div class="p-col-3" v-if="i18n.locale() == 'en' && police.tipocliente==1">
						{{$t('Sexo')}}: <strong>{{police.sexname}}</strong> 			
					</div>
					<div class="p-col-6">
						{{$t('Nombre Completo')}}: <strong>{{police.nombre}}</strong>			
					</div>
					<div class="p-col-6">
						{{$t('Correo')}}: <strong>{{police.correo}}</strong>			
					</div>
					<div class="p-col-4" v-if="police.tipocliente==1">
						{{$t('Fecha de nacimiento')}}: <strong>{{police.fechanac}}</strong>			
					</div>
					<div class="p-col-2" v-if="police.tipocliente==1">
						{{$t('Edad')}}: <strong>{{police.edad}}</strong>			
					</div>
					<div class="p-col-6" v-if="i18n.locale() == 'es'">
						{{$t('Nacionalidad')}}: <strong>{{police.nacionalidad}}</strong> 			
					</div>
					<div class="p-col-6" v-else>
						{{$t('Nacionalidad')}}: <strong>{{police.nationality}}</strong> 			
					</div>
					<div class="p-col-4">
						{{$t('Localidad')}}: <strong>{{police.localidadn}}</strong>			
					</div>
					<div class="p-col-4">
						{{$t('Teléfono móvil')}}: <strong>{{police.celular}}</strong>			
					</div>
					<div class="p-col-4">
						{{$t('Teléfono privado')}}: <strong>{{police.tlflocal}}</strong>			
					</div>
				</div>
            </div>
			<div class="card">
				<h4>{{$t('Pagos')}}</h4>
				<div class="p-fluid p-grid">
					<div class="p-col-12">
						<DataTable :value="police.deudas" stripedRows responsiveLayout="scroll">
							<Column :header="$t('Estatus')">
								<template #body="slotProps">
									<Avatar icon="bi bi-shield-check" class="p-mr-2" size="large" style="background-color:green; color: #ffffff" v-if="slotProps.data.pagada"/>
									<Avatar icon="bi bi-shield-exclamation" class="p-mr-2" size="large" style="background-color:yellow; color: #ffffff" v-else/>
									<strong v-if="slotProps.data.pagada">{{$t('Pagado')}}</strong>
									<strong v-else>{{$t('Pendiente')}}</strong>
								</template>
							</Column>
							<Column :header="$t('Fecha')">
								<template #body="slotProps">
									{{slotProps.data.fecha}}                              
								</template>
							</Column> 
							<Column field="factura" :header="$t('Factura')+' #'">
								<template #body="slotProps">
									{{slotProps.data.factura}}                              
								</template>
							</Column>
							<Column :header="$t('Monto')">
								<template #body="slotProps">
									<div v-if="slotProps.data.monto<0" style="color:red;">
										{{moneda(slotProps.data.monto)}}
									</div>
									<div v-else>
										{{moneda(slotProps.data.monto)}}
									</div>
								</template>
							</Column>
						</DataTable>
					</div>
					<div class="p-col-4">
						<h5><strong>{{$t('Pagos Recibidos')}}: {{moneda(anterior.datos.pagos+anterior.datos.proceso)}}</strong></h5>
					</div>
					<div class="p-col-4">
						<h5><strong>{{$t('Pagos Pendientes')}}: {{moneda(anterior.datos.deudas+anterior.datos.pendiente)}}</strong></h5>
					</div>
					<div class="p-col-4">
						<h5><strong>{{$t('Monto Total')}}: {{moneda(anterior.evaluacion.montofinal)}}</strong></h5>
					</div>		
				</div>
            </div>
            <div class="card">
                <h4>{{$t('Información del Vehículo')}}</h4>
                <div class="p-fluid p-grid">
					<div class="p-col-3">
						<label for="state">{{$t('Tipo de Vehículo')}}</label><br>
						<Dropdown v-model="police.tipovehiculo" :options="tipovehiculo" :optionLabel="$t('nombre')" :placeholder="$t('Seleccione...')"></Dropdown>
					</div>
					<div class="p-col-3">
						<label for="state">{{$t('Marca')}}</label><br>
						<Dropdown v-model="police.marca_id" :options="marcavehiculo" optionLabel="nombre" :placeholder="$t('Seleccione...')" @change="vermodelos()"></Dropdown>
					</div>
					<div class="p-col-3">
						<label for="state">{{$t('Módelo')}}</label><br>
						<Dropdown v-model="police.modelo_id" :options="modelos" optionLabel="nombre" :placeholder="$t('Seleccione...')" @change="veryears()"></Dropdown>
					</div>
					<div class="p-col-3">
						<label for="state">{{$t('Año de construcción')}}</label><br>
						<Dropdown v-model="police.veh_ano" :options="years" optionLabel="nombre" placeholder="...?" @change="Actualizacion1()"></Dropdown>		
					</div>
					<div class="p-col-2">
						<label>{{$t('Año de Referencia')}}</label>
						<InputMask v-model="police.veh_compra" mask="9999"/>	
					</div>
					<div class="p-col-2">
						<label for="state">{{$t('Automático')}}</label>
                        <Checkbox v-model="police.veh_automatico" :binary="true" />
					</div>
					<div class="p-col-2">
						<label>{{$t('Nº asientos')}}</label>
						<InputNumber v-model="police.veh_asientos" min="1" max="100" integeronly showButtons />
					</div>
					<div class="p-col-3">
						<label>{{$t('Número de chasis')}}</label>
						<InputText v-model="police.veh_chasis" type="text"/>
					</div>
					<div class="p-col-3">
						<label>{{$t('Nº placa')}}</label>
						<InputText v-model="police.veh_placa" type="text"/>
					</div>
					<div class="p-col-3">
						<label>{{$t('Color')}}</label>
						<InputText v-model="police.veh_color" type="text"/>
					</div>
					<div class="p-col-3">
						<label>{{$t('Posición del Volante')}}</label>
						<SelectButton v-model="police.veh_volante" :options="options" @click="Calculo()"/>
					</div>
				</div>
                 <br>
                <h4>Keuringskaart</h4>
                <div class="p-fluid p-grid">
					<div class="p-col-3">
						<label>{{$t('Fecha de emisión')}}</label>
                        <InputMask v-model="police.cardemi" placeholder="DD/MM/YYYY" slotChar="DD/MM/YYYY" mask="99/99/9999"/>
					</div>
					<div class="p-col-3">
						{{$t('Registro')}}: <strong>{{police.cardreg}}</strong>
                    <label>{{$t('Validez')}}</label>
						<Dropdown v-model="police.cardval" :options="validez" :optionLabel="$t('nombre')" :placeholder="$t('Seleccione...')"></Dropdown>
                    </div>
					<div class="p-col-3">
						<FileUpload :chooseLabel="$t('Subir')" uploadLabel="Cargar" mode="basic" :name="img" :url="url.api+'upload.php'"  accept="image/jpeg,image/jpg" :maxFileSize="4000000" @upload="myUploader()" :auto="true"/>
					</div>
					<div class="p-col-3">
						<img style="width: 100px;" :src="url.upload+police.veh_adverso" @click="toggle" v-if="police.veh_adverso"/>
					</div>
                </div>
            </div>
            <div class="card">
                <h4>{{$t('Evaluación')}}</h4>
                <h5 v-if="i18n.locale() == 'es'">{{$t('Tipo de Póliza')}}: <strong>{{police.evaluacion.tipopoliza_name}}</strong></h5>
                <h5 v-else>{{$t('Tipo de Póliza')}}: <strong>{{police.evaluacion.tipopoliza_name}}</strong></h5>     
                <div class="p-fluid p-grid">
                    <div class="p-col-12">
                        <Checkbox v-model="police.exoneracion" :binary="true"/> <strong>{{$t('Exoneracion')}}</strong><br><br>
                    </div>
					<div class="p-col-3">
						<label for="state">{{$t('Tarifa')}}</label>
						<Dropdown v-model="police.evaluacion.poliza_valor" :options="poliza_valores" :optionLabel="$t('nombre')" :placeholder="$t('Seleccione...')" @change="Actualizacion2()"></Dropdown>
					</div>
					<div class="p-col-3">
						<label>{{$t('Costo')}}</label> +{{kosten}}
						<InputText v-model="police.evaluacion.kosten" type="number" min="0" @change="Calculo()"/>
					</div>
					<div class="p-col-3">
						<label for="state">{{$t('Sin reclamación')}}</label>
						<Dropdown v-model="police.evaluacion.noclaim" :options="noclaims" optionLabel="nombre" :placeholder="$t('Seleccione...')" @change="Calculo()"></Dropdown>
					</div>	
					<div class="p-col-3">
						<label for="state">{{$t('Descuento Especial')}}</label>
						<Dropdown v-model="police.evaluacion.descuento" :options="descuentos" optionLabel="nombre" :placeholder="$t('Seleccione...')" @change="Calculo()" ></Dropdown>
					</div>	
					<div class="p-col-3">
						<label for="state">{{$t('Duración')}}</label>
						<Dropdown v-model="police.duracion" :options="duracion" :optionLabel="$t('nombre')" :placeholder="$t('Seleccione...')" @change="Calculo()" disabled></Dropdown>
					</div>	
					<div class="p-col-3" v-if="police.duracion">
						<label for="state">{{$t('Pagos')}}</label>
						<Dropdown v-model="police.cuotas" :options="cuotas" :optionLabel="$t('nombre')" :placeholder="$t('Seleccione...')" @change="Calculo()" v-if="police.duracion.code==0" disabled></Dropdown>
						<Dropdown v-model="police.cuotas" :options="cuotas2" :optionLabel="$t('nombre')" :placeholder="$t('Seleccione...')" @change="Calculo()" v-if="police.duracion.code==1" disabled></Dropdown>
						<Dropdown v-model="police.cuotas" :options="cuotas1" :optionLabel="$t('nombre')" placeholder="Select..." @change="Calculo()" v-if="police.duracion.code==2" disabled></Dropdown>
					</div>	
					<div class="p-col-12">
						<label>{{$t('Observación')}}</label>
						<Textarea v-model="police.evaluacion.comentario" rows="2"/>
					</div>
				</div>
            </div>
            <div class="card" v-if="vercalc">
				<h4>{{$t('Resumen')}}</h4>
				<div class="p-field" >
					<div class="p-fluid p-grid" >
						<div class="p-col-12">
							<DataTable :value="resumen">
								<Column :header="$t('Descrpición')">
									<template #body="slotProps">
										{{slotProps.data[i18n.locale()]}}                              
									</template>
								</Column> 
								<Column :header="$t('Detalle Nuevo')">
									<template #body="slotProps">
										{{slotProps.data.nuevo}}                              
									</template>
								</Column> 
								<Column :header="$t('Detalle Anterior')">
									<template #body="slotProps">
										{{slotProps.data.anterior}}                              
									</template>
								</Column> 
								<Column :header="$t('Diferencia')">
									<template #body="slotProps">
										{{slotProps.data.diferencia}}                              
									</template>
								</Column> 
							</DataTable>
						</div>						
					</div>
				</div>
                <div class="p-fluid p-grid">
                    <div class="p-col-12 p-md-8">
						<h4><strong>{{$t('Diferencia')}}: {{moneda(((parseFloat(premie*(1-police.evaluacion.noclaim.valor-police.evaluacion.descuento.valor)+parseFloat(police.evaluacion.adic_asientos))*(1+police.cuotas.porcentaje/100)*police.duracion.valor)+parseFloat(police.evaluacion.kosten)+parseFloat(kosten)+parseFloat(crs)+parseFloat(ob)-parseFloat(anterior.evaluacion.montofinal)).toFixed(2))}}</strong></h4>
					</div>
                    <div class="p-col-6 p-md-2">
                        <Button :label="$t('Procesar')" icon="pi pi-check" class="p-button-secondary" @click="Save" />
                    </div>
                    <div class="p-col-6 p-md-2">
                        <Button :label="$t('Cancelar')" icon="pi pi-times" class="p-button-danger" @click="displayeditar = false"/>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import API from "../service/ApiConsulta";
import { useI18nPlugin } from '@unify/vuex-i18n';
import url from "@/service/_URL";
import Token from "uuid-token-generator";
import PDF from "../service/PDF";


export default {
	components: {
    },
	data() {
		return {
			selectedCustomers: null,
			filters: {},
			i18n: null,
            //caso: null,
            poliza: [],
			url: null,
            buscar: null,
            polizas: [],
            displayview: false,
            police: {},
            estad: [],
            estados: [],
            displayeditar: false,
			noclaims: [],
			descuentos: [],
            duracion: [],
            cuotas: [],
            cuotas1: [],
            cuotas2: [],
            costo: null,
            years: [],
			poliza_tipos: [],
            poliza_valores: [],
            factor_limite: 0,
            recarga_edadveh: 0,
            recarga_edad: 0,
            recarga_volante: 0,
			adic_asientos: 0,
			premie: 0,
			kosten: 0,
			crs: 0,
			ob: 0,
			impuesto: 0,
            recargo: 0,
			vercalc: false,
			imagen: null,
			mensaje: null,
			procesar: false,
			renovacion: false,
			codimg: null,
			tipovehiculo: [],
			marcavehiculo: [],
			modelos: [],
			img: null,
            size: '60vw',
            fecha: null,
			options: ['<- Left', 'Right->'],
			validez: [],
			anterior: null,
			resumen: [
				{es: 'Duración', en: 'Duration', anterior: null, nuevo: null, diferencia: null},
				{es: 'Valor de Vehículo', en: 'Vehicle Value', anterior: null, nuevo: null, diferencia: null},
				{es: 'Prima Bruta WA', en: 'Gross premium WA', anterior: null, nuevo: null, diferencia: null},
				{es: 'Recargo Años del Vehículo', en: 'Vehicle Age Surcharge', anterior: null, nuevo: null, diferencia: null},
				{es: 'Recargo Edad Persona', en: 'Age Person Surcharge', anterior: null, nuevo: null, diferencia: null},
				{es: 'Recargo Volante', en: 'Steering Wheel Surcharge', anterior: null, nuevo: null, diferencia: null},
				{es: 'Total WA', en: 'Total WA', anterior: null, nuevo: null, diferencia: null},
				{es: 'Sin reclamación WA', en: 'No Claim WA', anterior: null, nuevo: null, diferencia: null},
				{es: 'Cobertura Adicional', en: 'Additional Coverage', anterior: null, nuevo: null, diferencia: null},
				{es: 'Forma de pago', en: 'Payment Method', anterior: null, nuevo: null, diferencia: null},
				{es: 'Descuento Especial WA', en: 'Special Discount WA', anterior: null, nuevo: null, diferencia: null},
				{es: 'Premie WA', en: 'Premie WA', anterior: null, nuevo: null, diferencia: null},
				{es: 'Costo', en: 'Cost', anterior: null, nuevo: null, diferencia: null},
				{es: 'OB', en: 'OB', anterior: null, nuevo: null, diferencia: null},
				{es: 'Monto', en: 'Amount', anterior: null, nuevo: null, diferencia: null},
			],

        }
	},
	productService: null,
	eventService: null,
	created() {
		this.url = url;
		this.i18n = useI18nPlugin();
		this.$store.commit('Validar',this.$route);
		if (this.$store.state.mobile){this.size = '90vw';}
		const tokgen = new Token(256);
		this.img = tokgen.generate();
        this.Mostrar();		
	},
	mounted() {
	},
	methods: {
        Mostrar(){
			this.$store.commit('Loading');
			this.estados = [];
            this.Dialogo = false;
			this.duracion = [];
			this.cuotas = [];
			this.cuotas1 = [];
            this.cuotas2 = [];
            const Consulta = new API('Configuracion');
            Consulta.Ini('Cambio').then(result => {
                //this.$store.state.error = result.consult.tipovehiculo;
				this.insert = result.insert;
				this.edit = result.edit;
				this.del = result.delete;
				this.estados = result.consult.estados;
				this.noclaims = result.consult.noclaims;
				this.descuentos = result.consult.descuentos;
                this.duracion = result.consult.duracion;
				this.tipovehiculo = result.consult.tipovehiculo;
				this.marcavehiculo = result.consult.marcavehiculo;
				this.validez = result.consult.validez;
                this.cuotas = result.consult.cuotas;
				this.cuotas.forEach(element => {
					if(element.code==0){
						this.cuotas1.push(element);
						this.cuotas2.push(element);
					}
					if(element.code==1){
						this.cuotas2.push(element);
					}
				});
                this.kosten = result.consult.valores.costo1;
				this.crs = result.consult.valores.costo2;
				this.impuesto = result.consult.valores.impuesto;
                this.$store.commit('Loading');
            }); 
        },
        VerPDF(data){
            this.$store.commit('Loading');
            const Consulta = new API('Configuracion');
			Consulta.Procesar('Cambio',{
				caso: 	'VerPDF',
                poliza: data.id,
				}).then(response => {
					//this.$store.state.error = data;
                    this.$store.commit('Loading');
                    const doc = new PDF();
                    doc.Cambio(data,response.result);
                });           
        },
        Buscar(){
			this.$store.commit('Loading');
			this.vercalc = false;
			this.displayeditar = false;
			this.poliza = [];
            const Consulta = new API('Configuracion');
			Consulta.Procesar('Cambio',{
				caso: 	'Buscar',
				id: this.buscar,
				}).then(response => {
					//this.$store.state.error = response.result.poliza;	
                    if (response.result.poliza){
						this.anterior = response.result.poliza;
                        this.poliza = [response.result.poliza];
                        this.poliza_tipos = response.result.poliza_tipos;
                        this.poliza_valores = response.result.poliza_valores;
						this.years = response.result.years;
                    }
                    this.$store.commit('Loading');
                });
        },
		Editar(data) {
            this.factor_limite = 0;
            this.recarga_edadveh = 0;
            this.recarga_edad = 0;
            this.recarga_volante = 0;
			this.premie = 0;
			this.ob = 0;
            this.recargo = 0;

			this.$store.commit('Loading');
			this.police = {...data};
			if(this.police.tipovehiculo){
				this.police.tipovehiculo = this.tipovehiculo.filter(val => val.code == this.police.tipovehiculo)[0];
			}
			if(this.police.marca_id){
				this.police.marca_id = this.marcavehiculo.filter(val => val.code == this.police.marca_id)[0];
				this.vermodelos();
			}
			if(this.police.cardval){
				this.police.cardval = this.validez.filter(val => val.code == this.police.cardval)[0];
			}
            this.poliza_tipos.forEach(element => {
                if(this.police.evaluacion.tipopoliza==element.code){
					this.police.evaluacion.tipopoliza = element;
                 }
            });  
            this.poliza_valores.forEach(element => {
                if(this.police.evaluacion.poliza_valor==element.code){
					this.police.evaluacion.poliza_valor = element;
                }
            });
            this.noclaims.forEach(element => {
                if(this.police.evaluacion.noclaim_id==element.code){
					this.police.evaluacion.noclaim = element;
                }
                if(this.police.anterior.noclaim==element.code){
					this.police.anterior.noclaim = element;
                }
            });  
            this.descuentos.forEach(element => {
                if(this.police.evaluacion.descuento==element.code){
					this.police.evaluacion.descuento = element;
                }
                if(this.police.anterior.descuento==element.code){
					this.police.anterior.descuento = element;
                }
            }); 
            this.years.forEach(element => {
                if(this.police.vehano==element.code){
					this.police.veh_ano = element;
                }
            });
			this.duracion.forEach(element => {
				if(this.police.duracion==element.code){
					this.police.duracion = element;
				}
			});  
			this.cuotas.forEach(element => {
				if(this.police.cuotas==element.code){
					this.police.cuotas = element;
				}
			});
			const Consulta = new API('Configuracion');
			this.police.cardemi = Consulta.fstring(this.police.cardemi);
			this.Actualizacion1();
			this.Calculo();
            this.$store.commit('Loading');
            this.displayeditar = true;
		},
        Actualizacion1() {
            this.police.evaluacion.valorcatalogo = this.police.veh_ano.valor;
            this.police.evaluacion.limite = this.police.veh_ano.valor*this.police.factorlimite.limite;
			this.Actualizacion2();
        },
		Actualizacion2() {
			if (this.police.veh_ano && this.police.evaluacion.poliza_valor!=null){
				this.vercalc = false;
				this.$store.commit('Loading');
				const Consulta = new API('Configuracion');
				Consulta.Procesar('Cambio',{
					caso: 	'Actualizacion2',
					poliza_valor: this.police.evaluacion.poliza_valor.code,
					//valorcatalogo: this.police.evaluacion.valorcatalogo,
					valorcatalogo: this.police.veh_ano.valor,
					tipocliente: this.police.tipocliente,
					tipopoliza: this.police.evaluacion.tipopoliza.code,
				}).then(response => {
					//this.$store.state.error = response;
					this.police.evaluacion.limite = response.result.limite;
					this.police.evaluacion.primabruta = response.result.primabruta;
					this.Calculo();
					this.$store.commit('Loading');
					this.vercalc = true;
				}); 
			}
		},
		vermodelos() {
			if(this.police.marca_id.code>0){
				this.$store.commit('Loading');
				this.modelos = [];
				const Consulta = new API('Configuracion');
				Consulta.Procesar('Cambio',{
					caso: 	'vermodelos',
					marca: this.police.marca_id.code,
				}).then(response => {
					//this.$store.state.error = response;
					if (response.result){
						this.modelos = response.result;
						if(this.police.modelo_id){
							this.police.modelo_id = this.modelos.filter(val => val.code == this.police.modelo_id)[0];
						}
					}
					this.$store.commit('Loading');
				});
			}
        },
		veryears() {
			if(this.police.modelo_id.code>0){
				this.$store.commit('Loading');
				//this.vehiculo.year_id = null;
				const Consulta = new API('Configuracion');
				Consulta.Procesar('Cambio',{
					caso: 	'veryears',
					modelo: this.police.modelo_id.code,
				}).then(response => {
					//this.$store.state.error = response;
					if (response.result){
						this.years = response.result;
					}
					this.$store.commit('Loading');
				});
			}
        },
		Calculo(){
			this.premie = 0;
			this.recarga_edadveh = 0;
            this.recarga_edad = 0;
			this.adic_asientos = parseFloat(this.police.tipovehiculo.factor*this.police.veh_asientos).toFixed(2);
			if (this.police.edadveh>7){
				this.recarga_edadveh = parseFloat(this.police.evaluacion.primabruta*this.police.evaluacion.limite*0.2).toFixed(2);
			}
			if (this.police.edad>=65){
				this.recarga_edad = parseFloat(this.police.evaluacion.primabruta*this.police.evaluacion.limite*0.2).toFixed(2);
			}
			if (this.police.veh_volante=="Right->"){
				this.recarga_volante = parseFloat(this.police.evaluacion.primabruta*this.police.evaluacion.limite*0.2).toFixed(2);
			} else {
				this.recarga_volante = 0;
			}
			if(this.police.evaluacion.tipopoliza.casco){
				this.premie = (parseFloat(this.police.evaluacion.primabruta)+parseFloat(this.police.evaluacion.valorcatalogo)*parseFloat(this.police.evaluacion.limite)+parseFloat(this.recarga_edadveh)+parseFloat(this.recarga_edad)+parseFloat(this.recarga_volante)).toFixed(2);
			} else {
				this.premie = (parseFloat(this.police.evaluacion.primabruta)*parseFloat(this.police.evaluacion.limite)+parseFloat(this.recarga_edadveh)+parseFloat(this.recarga_edad)+parseFloat(this.recarga_volante)).toFixed(2);
			}
			this.ob = (((parseFloat(this.premie*(1-this.police.evaluacion.noclaim.valor-this.police.evaluacion.descuento.valor)+parseFloat(this.adic_asientos))*this.police.duracion.valor*(1+this.police.cuotas.porcentaje/100))+parseFloat(this.police.evaluacion.kosten)+parseFloat(this.kosten)+parseFloat(this.crs))*this.impuesto/100).toFixed(2);

			this.resumen[0].nuevo = this.police.duracion.name;
			//this.resumen[0].anterior = this.anterior.duracion;

			this.resumen[1].nuevo = this.moneda(this.police.evaluacion.valorcatalogo);
			this.resumen[1].anterior = this.moneda(this.police.anterior.valorcatalogo);
			this.resumen[1].diferencia = this.moneda(this.police.evaluacion.valorcatalogo-this.police.anterior.valorcatalogo);

			let primabrutanue = 0;
			if(this.police.evaluacion.tipopoliza.casco){
				primabrutanue = (parseFloat(this.police.evaluacion.primabruta) + parseFloat(this.police.evaluacion.valorcatalogo*this.police.evaluacion.limite));
			} else {
				primabrutanue = (parseFloat(this.police.evaluacion.primabruta*this.police.evaluacion.limite));
			}
			this.resumen[2].nuevo = this.moneda(primabrutanue);
			this.resumen[2].anterior = this.moneda(this.police.anterior.primabruta);
			this.resumen[2].diferencia = this.moneda(primabrutanue-this.police.anterior.primabruta);

			this.resumen[3].nuevo = this.moneda(this.recarga_edadveh);
			this.resumen[3].anterior = this.moneda(this.police.anterior.recarga_veh);
			this.resumen[3].diferencia = this.moneda(this.recarga_edadveh-this.police.anterior.recarga_veh);

			this.resumen[4].nuevo = this.moneda(this.recarga_edad);
			this.resumen[4].anterior = this.moneda(this.police.anterior.recarga_edad);
			this.resumen[4].diferencia = this.moneda(this.recarga_edad-this.police.anterior.recarga_edad);

			this.resumen[5].nuevo = this.moneda(this.recarga_volante);
			this.resumen[5].anterior = this.moneda(this.police.anterior.recarga_volante);
			this.resumen[5].diferencia = this.moneda(this.recarga_volante-this.police.anterior.recarga_volante);
			
			this.resumen[6].nuevo = this.moneda(this.premie);
			this.resumen[6].anterior = this.moneda(this.anterior.evaluacion.totalwa);
			this.resumen[6].diferencia = this.moneda(this.premie-this.anterior.evaluacion.totalwa);

			this.resumen[7].nuevo = this.moneda(this.premie*this.police.evaluacion.noclaim.valor);
			this.resumen[7].anterior = this.moneda(this.anterior.evaluacion.totalwa*this.police.anterior.noclaim.valor);
			this.resumen[7].diferencia = this.moneda(this.premie*this.police.evaluacion.noclaim.valor-this.anterior.evaluacion.totalwa*this.police.anterior.noclaim.valor);

			this.resumen[8].nuevo = this.moneda(this.adic_asientos);
			this.resumen[8].anterior = this.moneda(this.police.anterior.adic_asientos);
			this.resumen[8].diferencia = this.moneda(this.adic_asientos-this.police.anterior.adic_asientos);

			let pagomet = (this.premie*(1-this.police.evaluacion.noclaim.valor)+parseFloat(this.adic_asientos))*parseFloat(this.police.cuotas.porcentaje/100);
			let pagometant = (parseFloat(this.anterior.evaluacion.totalwa)*(1-parseFloat(this.police.anterior.noclaim.valor))+parseFloat(this.police.anterior.adic_asientos))*parseFloat(this.police.cuotas.porcentaje/100);
			this.resumen[9].nuevo = this.moneda(pagomet);
			this.resumen[9].anterior = this.moneda(pagometant);
			this.resumen[9].diferencia = this.moneda(pagomet-pagometant);

			this.resumen[10].nuevo = this.moneda(this.premie*this.police.evaluacion.descuento.valor);
			this.resumen[10].anterior = this.moneda(this.anterior.evaluacion.totalwa*this.police.anterior.descuento.valor);
			this.resumen[10].diferencia = this.moneda(this.premie*this.police.evaluacion.descuento.valor-this.anterior.evaluacion.totalwa*this.police.anterior.descuento.valor);	

			let totalnue = ((parseFloat(this.premie*(1-this.police.evaluacion.noclaim.valor-this.police.evaluacion.descuento.valor))+parseFloat(this.adic_asientos))*(1+this.police.cuotas.porcentaje/100)*this.police.duracion.valor);
			let totalvie = ((parseFloat(this.anterior.evaluacion.totalwa*(1-this.police.anterior.noclaim.valor-this.police.anterior.descuento.valor))+parseFloat(this.police.anterior.adic_asientos))*(1+this.police.cuotas.porcentaje/100)*this.police.duracion.valor);
			this.resumen[11].nuevo = this.moneda(totalnue);
			this.resumen[11].anterior = this.moneda(totalvie);
			this.resumen[11].diferencia = this.moneda(totalnue-totalvie);

			this.resumen[12].nuevo = this.moneda(parseFloat(this.police.evaluacion.kosten)+parseFloat(this.kosten));
			this.resumen[12].anterior = this.moneda(this.police.anterior.kosten);
			this.resumen[12].diferencia = this.moneda(parseFloat(this.police.evaluacion.kosten)+parseFloat(this.kosten)-+parseFloat(this.police.anterior.kosten));

			this.resumen[13].nuevo = this.moneda(this.ob);
			this.resumen[13].anterior = this.moneda(this.anterior.evaluacion.ob);
			this.resumen[13].diferencia = this.moneda(this.ob-this.anterior.evaluacion.ob);

			let finalnue = ((parseFloat(this.premie*(1-this.police.evaluacion.noclaim.valor-this.police.evaluacion.descuento.valor)+parseFloat(this.adic_asientos))*(1+this.police.cuotas.porcentaje/100)*this.police.duracion.valor)+parseFloat(this.police.evaluacion.kosten)+parseFloat(this.kosten)+parseFloat(this.crs)+parseFloat(this.ob));
			this.resumen[14].nuevo = this.moneda(finalnue);
			this.resumen[14].anterior = this.moneda(this.anterior.evaluacion.montofinal);
			this.resumen[14].diferencia = this.moneda(finalnue-this.anterior.evaluacion.montofinal);
		},	
		myUploader() {
			this.$store.commit('Loading');
			const Consulta = new API('Cotizacions');
			Consulta.Procesar('Vehiculo',{
				caso: 	'RevFoto',
				foto: this.img,
			}).then(response => {
				if(response.result){
					this.police.veh_adverso = this.img+'.jpg';
				} else {
					this.foto = response.result;
					this.$toast.add({
						severity:'warn', 
						summary: this.$t('Advertencia'), 
						detail: this.$t('Error en Foto'), 
						life: 3000
					});
				}
                const tokgen = new Token(256);
                this.img = tokgen.generate();
				this.$store.commit('Loading');
			});
		},
        convfech(fecha){
            return fecha.replace(/^(\d{4})-(\d{2})-(\d{2})$/g,'$3/$2/$1');
		},
        Save(){
			if (
				this.police.tipovehiculo == null	||
				this.police.marca_id == null	||
				this.police.modelo_id == null	||
				this.police.veh_ano == null	||
				this.police.veh_compra == null	||	this.police.veh_compra == ''	||
				this.police.veh_chasis == null	||	this.police.veh_chasis == ''	||
				this.police.veh_placa == null	||	this.police.veh_placa == ''		||	
				this.police.veh_color == null	||	this.police.veh_color == ''		||
				this.police.cardemi == null		||	this.police.cardemi == ''		||
				this.police.cardval == null		||	this.police.cardval == ''		||
				this.police.veh_adverso == null	||	this.police.veh_adverso == ''	||
				this.police.evaluacion.poliza_valor == null	||
				this.police.evaluacion.noclaim == null	||	
				this.police.evaluacion.descuento == null
			) {
				this.$toast.add({severity:'error', summary: 'Mensaje', detail: this.$t('Error'), life: 10000});
			} else {
				this.$store.commit('Loading');
				//let totalnuebruta = ((parseFloat(this.premie*(1-this.police.evaluacion.noclaim.valor-this.police.evaluacion.descuento.valor))+parseFloat(this.police.evaluacion.adic_asientos))*this.police.duracion.valor);
				let diferencia = ((parseFloat(this.premie*(1-this.police.evaluacion.noclaim.valor-this.police.evaluacion.descuento.valor)+parseFloat(this.police.evaluacion.adic_asientos))*(1+this.police.cuotas.porcentaje/100)*this.police.duracion.valor)+parseFloat(this.police.evaluacion.kosten)+parseFloat(this.kosten)+parseFloat(this.crs)+parseFloat(this.ob)-parseFloat(this.anterior.evaluacion.montofinal));
				const Consulta = new API('Configuracion');
				Consulta.Procesar('Cambio',{
					caso: 	'UPD',
					police: this.police,
					anterior: this.anterior,
					recarga_edad: this.recarga_edad,
					recarga_veh: this.recarga_edadveh,
					recarga_volante: this.recarga_volante,
					crs: this.crs,
					ob: this.ob,
					kosten: this.kosten,
					renovacion: this.renovacion,
					leng: this.i18n.locale(),
					diferencia: diferencia,
					fecha: Consulta.fdate(this.police.cardemi),
					//broker_monto: parseFloat(totalnuebruta)*parseFloat(this.police.evaluacion.broker/100),
					}).then(response => {
						//this.$store.state.error = response;	
						if (response.result){
							this.Buscar();
						}
						this.$store.commit('Loading');
					});

			}
        },
        moneda(number){
            return new Intl.NumberFormat("en", {style: "currency", currency: this.$store.state.currency}).format(parseFloat(number).toFixed(2));
		},
        fdate(fecha){
            const [dia, mes, ano] = fecha.split("/");
            let cambiada = `${ano}-${mes}-${dia}`;
            return cambiada;
        },

	},
	computed: {

    }

}
</script>
<style scoped lang="scss">
.table-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.product-image {
	width: 100px;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.p-dialog .product-image {
	width: 150px;
	margin: 0 auto 2rem auto;
	display: block;
}

.confirmation-content {
	display: flex;
	align-items: center;
	justify-content: center;
}

.product-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.status-instock {
		background: #C8E6C9;
		color: #256029;
	}

	&.status-outofstock {
		background: #FFCDD2;
		color: #C63737;
	}

	&.status-lowstock {
		background: #FEEDAF;
		color: #8A5340;
	}
}

.p-datatable-responsive-demo .p-datatable-tbody > tr > td .p-column-title {
    display: none;
}

@media screen and (max-width: 40em) {
    ::v-deep(.p-datatable) {
        &.p-datatable-responsive-demo {
            .p-datatable-thead > tr > th,
            .p-datatable-tfoot > tr > td {
                display: none !important;
            }

            .p-datatable-tbody > tr > td {
                text-align: left;
                display: block;
                width: 100%;
                float: left;
                clear: left;
                border: 0 none;

                .p-column-title {
                    padding: .4rem;
                    min-width: 30%;
                    display: inline-block;
                    margin: -.4em 1em -.4em -.4rem;
                    font-weight: bold;
                }

                &:last-child {
					border-bottom: 1px solid var(--surface-d);
					text-align: center;
				}
				
				.p-rating {
					display: inline-block;
				}
            }
        }
    }
}
</style>
